import { defineStore } from 'pinia';
import { ref } from 'vue';

import { configService, masterDataService } from '@/core/services';
import type { components } from '@/types';

type Item = components['schemas']['Item'];

const useMasterDataStore = defineStore('masterData', () => {
  // state
  const jobTitles = ref<Item[]>([]);
  const timezones = ref<Item[]>([]);
  const usages = ref<Item[]>([]);
  const channels = ref<Item[]>([]);
  const interests = ref<Item[]>([]);

  const minAppVersion = ref('');
  const mandatoryUpdateAvailable = ref(false);
  const show409Modal = ref(false);

  const showMandatorySetup = ref(false);

  // actions
  function loadConfig() {
    configService.getConfig().then((response) => {
      const version = response?.minAppVersion;
      if (version) {
        minAppVersion.value = `${version.major}.${version.minor}.${version.patch}`;
      }
    });
  }

  function handle409Conflict() {
    console.debug('409 conflict detected');
    show409Modal.value = true;
  }

  function loadMasterData() {
    masterDataService
      .getMasterData()
      .then(
        (response: {
          success: boolean;
          data?: components['schemas']['AccountAttributes'];
          error?: any;
        }) => {
          if (response.success) {
            jobTitles.value = response.data?.jobTitles ?? [];
            timezones.value = response.data?.timezones ?? [];
            usages.value = response.data?.usages ?? [];
            channels.value = response.data?.channels ?? [];
            interests.value = response.data?.interests ?? [];
          } else {
            jobTitles.value = [];
            timezones.value = [];
            usages.value = [];
            channels.value = [];
            interests.value = [];
          }
        },
      );
  }

  return {
    // state
    jobTitles,
    timezones,
    usages,
    channels,
    interests,
    minAppVersion,
    mandatoryUpdateAvailable,
    show409Modal,
    showMandatorySetup,

    // actions
    loadConfig,
    loadMasterData,
    handle409Conflict,
  };
});

export { useMasterDataStore };
