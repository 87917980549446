<script setup lang="ts">
import { computed, inject } from 'vue';
import { useRoute } from 'vue-router';

import type { components } from '@/types';
import { assertIsDefined } from '#/components/uiTour/share';

import { THE_SIDE_BAR_MAIN_MENU_CONTEXT_KEY } from './share';

const props = withDefaults(
  defineProps<{
    to: '/freight' | '/cargo' | '/access';
    icon: 'freight' | 'basis' | 'access';
    iconSize?: string;
    subscriptionPlan:
      | 'trial-plan'
      | 'basic-plan'
      | 'premium-plan'
      | 'no-plan'
      | undefined;
    showRedDot?: boolean;
  }>(),
  { iconSize: '20', showRedDot: false },
);

const context = inject(THE_SIDE_BAR_MAIN_MENU_CONTEXT_KEY);
assertIsDefined(
  context,
  'TheSidebarMainMenuProductLink requires TheSidebarMainMenu as parent',
);
const { isExpanded } = context;

const route = useRoute();

const isFreightRoute = computed(
  () => route.meta.product === 'lng-freight-platform',
);

const isCargoRoute = computed(
  () => route.meta.product === 'lng-basis-platform',
);

const isAccessRoute = computed(() => route.meta.product === 'access-platform');

const activeRoute = computed(() => {
  if (isFreightRoute.value && props.to === '/freight') {
    return 'freight';
  }
  if (isCargoRoute.value && props.to === '/cargo') {
    return 'cargo';
  }
  if (isAccessRoute.value && props.to === '/access') {
    return 'access';
  }
  return undefined;
});

const PLAN: Partial<
  Record<components['schemas']['SubscriptionPlanEnum'], string>
> = {
  'trial-plan': 'Trial',
  'basic-plan': 'Basic',
};
</script>

<template>
  <RouterLink
    :to="to"
    class="col-span-2 grid h-11 grid-cols-[subgrid] text-gray-400 transition-colors hover:bg-gray-700/50 hover:text-gray-100 data-[route=access]:bg-access-500 data-[route=cargo]:bg-basis-500 data-[route=freight]:bg-green-500 data-[route=access]:text-blue-900 data-[route=cargo]:text-gray-100 data-[route=freight]:text-gray-100"
    :data-route="activeRoute"
  >
    <slot name="icon">
      <div v-if="icon" class="relative flex items-center justify-center">
        <SparkIcon :icon="icon" :size="iconSize" />
        <sup
          v-if="showRedDot"
          class="absolute right-2 top-1 block h-[6px] w-[6px] rounded-full bg-red"
        ></sup>
      </div>
    </slot>
    <div
      class="flex w-[calc(100px-1rem)] items-center overflow-hidden text-base font-semibold opacity-0 transition-opacity data-[expanded=true]:opacity-100"
      :data-expanded="isExpanded"
    >
      <slot />
      <div
        v-if="
          subscriptionPlan === 'basic-plan' || subscriptionPlan === 'trial-plan'
        "
        class="ml-auto rounded bg-orange-600 px-1 py-0.5 text-[0.6rem] font-semibold leading-none text-white"
      >
        {{ PLAN[subscriptionPlan] }}
      </div>
      <SparkIcon
        v-if="subscriptionPlan === 'premium-plan'"
        icon="crown"
        size="16"
        class="ml-auto"
      ></SparkIcon>
    </div>
  </RouterLink>
</template>
