import { Fragment, type InjectionKey, type VNode } from 'vue';
import {} from 'vue';

import { UITourContext, UITourInternalContext } from './types';

export const UI_TOUR_STOP_ID_ATTR = 'data-ui-tour-stop-id';

export const UI_TOUR_CONTEXT_KEY = Symbol(
  'UITourContext',
) as InjectionKey<UITourContext>;

export const UI_TOUR_INTERNAL_CONTEXT_KEY = Symbol(
  'UITourInternalContext',
) as InjectionKey<UITourInternalContext>;

export function assertIsDefined<T>(
  value: T,
  message?: string,
): asserts value is NonNullable<T> {
  if (value === undefined || value === null) {
    throw new Error(message || 'Value is not defined');
  }
}

export function renderSlotFragments(children?: VNode[]): VNode[] {
  if (!children) {
    return [];
  }
  return children.flatMap((child) => {
    if (child.type === Fragment) {
      return renderSlotFragments(child.children as VNode[]);
    }
    return [child];
  });
}
