import ApiBase from '@/core/services/ApiBase';

const getJsonApiData = function (response: any) {
  return response?.data?.data;
};

export default class ConfigService extends ApiBase {
  getConfig() {
    return this.$http.get('config').then(getJsonApiData);
  }
}
